// src/pages/DevPage.js
import React from 'react';
import OnboardingCards from '../components/OnboardingCards';

function DevPage() {
  return (
    <div>
      <OnboardingCards />
    </div>
  );
}

export default DevPage;