import React, { useState, useEffect } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Ensure this path is correct
import './HomePage.css';

const HomePage = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');

  useEffect(() => {
    fetchUserData();
  }, []);

  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('user_data')
          .select('name')
          .eq('user_uuid', user.id)
          .single();

        if (error) throw error;
        if (data && data.name !== 'New User') {
            setUserName(data.name);
          }
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  const handleMeditationClick = () => {
    navigate('/zen')
  };

  const handleQuestionsClick = () => {
    navigate('/practice');
  };

  return (
    <div className='home-page'>
    <div className="home-container">
      <div className="content">
      <h1>{userName ? `Hello, ${userName}` : 'Hello,'}</h1>
        <p className="hometext">
          Today is a great day to make progress towards passing the bar. <br /><br />Want to dive right in or take a moment to mentally prepare? 
        </p>
        <div className="button-container">
          <button className="action-button" onClick={handleMeditationClick}>
            <span>Minute<br />Meditation</span>
          </button>
          <button className="action-button" onClick={handleQuestionsClick}>
            <span>MBE<br />Practice</span>
          </button>
        </div>
        {/* <input
          type="text"
          placeholder="Talk to me"
          className="chat-input-home"
        /> */}
      </div>
      <div className = "spacer"></div>
    </div>
    </div>
  );
};

export default HomePage;