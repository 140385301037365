// src/components/OnboardingCards.js
import React, { useState } from 'react';
import { supabase } from '../supabaseClient';
import '../App.css';


const OnboardingCards = ({ userUuid, isNewUser, onComplete }) => {
  const [currentCard, setCurrentCard] = useState(0);
  const [userName, setUserName] = useState('');

  const cards = [
    {
      title: "Welcome to JurisGenius!",
      content: "Unlock your potential with our AI-driven bar exam tutor designed to help you pass the bar in half the time."
    },
    {
      title: "Discover Secret Strategies",
      content: "Streamline preparation & enhance your exam score with our exclusive techniques. Just promise to use them responsibly."
    },
    {
      title: "Over 2,000 Real Bar Questions",
      content: "Train for it by doing it, under the eye of your one-of-a-kind, AI tutor. It builds a profile analyzing exactly what you need to do to improve & help you focus on it."
    },
    {
      title: "Mind Is the Matter Here",
      content: "Scientifically-backed mindfulness exercises designed to improve concentration, reduce stress, and optimize exam performance."
    },
    {
      title: "Let's Get Started!",
      content: "What's your name? As you practice, Juris will adapt to your learning style and maximize your efficiency.",
      isLastCard: true
    }
  ];

  const handleNext = async () => {
    if (currentCard === cards.length - 1) {
      if (!userName.trim()) {
        alert("Please enter your name before continuing.");
        return;
      }
      
      const { data, error } = await supabase
      .from('user_data')
      .update({ name: userName.trim() })
      .eq('user_uuid', userUuid)
      .select();
  
      if (error) {
        console.error('Error updating user name:', error);
        alert("There was an error saving your name. Please try again.");
      } else {
        console.log("User name updated successfully:", data);
        onComplete();
      }
    } else {
      setCurrentCard(currentCard + 1);
    }
  };
  if (!isNewUser) {
  return null;
  }

  return (
    <div className="onboarding-cards">
      <h2>{cards[currentCard].title}</h2>
      <p>{cards[currentCard].content}</p>
      {cards[currentCard].isLastCard && (
        <input
          type="text"
          value={userName}
          onChange={(e) => setUserName(e.target.value)}
          placeholder="Enter your name"
        />
      )}
      <button onClick={handleNext}>
        {currentCard === cards.length - 1 ? "Finish" : "Next"}
      </button>
    </div>
  );
};

export default OnboardingCards;