import React, { useState, useLayoutEffect, useEffect, useCallback, useRef } from 'react';
import { debounce } from 'lodash';
import scrollIntoView from 'smooth-scroll-into-view-if-needed';
import MarkdownRenderer from '../cmp/MarkdownRenderer';
import { useNavigate } from 'react-router-dom';

const Chat = ({ api, userUuid, sessionId, currentQuestion, setCurrentQuestion }) => {
  const [message, setMessage] = useState('');
  const [chatHistory, setChatHistory] = useState([]);
  const [isMobile, setIsMobile] = useState(window.innerWidth <= 768);
  const [streamingMessage, setStreamingMessage] = useState('');
  const [toolCallIndicator, setToolCallIndicator] = useState('');
  const [isStreaming, setIsStreaming] = useState(false);

  const messageRef = useRef(null);
  const chatHistoryRef = useRef(null);
  const chatContainerRef = useRef(null);
  const chatInputRef = useRef(null);
  const streamingMessageRef = useRef(null);

  const navigate = useNavigate();

  const openStream = async () => {
    try {
      const response = await fetch(
        `${api.defaults.baseURL}/api/chat/processMessage`,
        {
          method: 'POST',
          headers: {
            'Content-Type': 'application/json',
          },
          body: JSON.stringify({ sessionId, message, user_uuid: userUuid }),
        }
      );
      
      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }
    
    
    setIsStreaming(true);
    const reader = response.body.getReader();
    const decoder = new TextDecoder();
    
    let completeMessage = '';
    let buffer = '';
    let messageResponseReceived = false;

    while (true) {
      const { value, done } = await reader.read();
      if (done) break;
      
      buffer += decoder.decode(value, { stream: true });
      const lines = buffer.split('\n');
      buffer = lines.pop();
      
      for (const line of lines) {
        if (line.startsWith('data: ')) {
          try {
            const parsedValue = JSON.parse(line.slice(6));
            console.log('Received:', parsedValue);
            
            if (parsedValue.type === 'message response') {
              messageResponseReceived = true;
              completeMessage += parsedValue.text;
              setStreamingMessage(completeMessage);
              setChatHistory(prevHistory => {
                const updatedHistory = [...prevHistory];
                if (updatedHistory.length > 0 && updatedHistory[updatedHistory.length - 1].role === 'assistant') {
                  updatedHistory[updatedHistory.length - 1].content = completeMessage;
                } else {
                  updatedHistory.push({ role: 'assistant', content: completeMessage });
                }
                return updatedHistory;
              });
            } else if (parsedValue.type === 'function') {
              setToolCallIndicator(`Processing tool call: ${parsedValue.toolName}`);
            } else if (parsedValue.type === 'question response') {
              setCurrentQuestion(parsedValue.question);
              setChatHistory([]);
              completeMessage = '';
              messageResponseReceived = false;
            }
          } catch (error) {
            console.error('Error parsing JSON:', error);
          }
        }
      }
    }
    
    setStreamingMessage('');
    setToolCallIndicator('');
    setIsStreaming(false);
  } catch (error) {
    console.error('Streaming error:', error);
    setIsStreaming(false);
  }
};
  

  const sendMessage = async () => {
    if (message.trim() === '' || isStreaming) return;

    setIsStreaming(true);
    const newChatHistory = [...chatHistory, { role: 'user', content: message }];
    setChatHistory(newChatHistory);
    setMessage('');
    setStreamingMessage('');
    adjustTextareaHeight();

    try {
      await openStream();
    } finally {
      setIsStreaming(false);
    }
  };

  const handleKeyPress = (e) => {
    if (e.key === 'Enter' && !e.shiftKey && !isStreaming) {
      e.preventDefault();
      sendMessage();
    }
  };

  const adjustTextareaHeight = () => {
    if (messageRef.current) {
      messageRef.current.style.height = 'auto';
      messageRef.current.style.height = `${Math.min(messageRef.current.scrollHeight, 100)}px`;
    }
  };

  const scrollToBottom = useCallback(() => {
    if (chatHistoryRef.current) {
      const scrollContainer = chatHistoryRef.current;
      scrollContainer.scrollTop = scrollContainer.scrollHeight;
    }
  }, []);

  const handleMobileLayout = () => {
    if (isMobile && window.visualViewport) {
      const viewportHeight = window.visualViewport.height;
      const keyboardHeight = window.innerHeight - viewportHeight;

      if (chatContainerRef.current) {
        chatContainerRef.current.style.height = `${viewportHeight}px`;
        chatContainerRef.current.style.position = 'fixed';
        chatContainerRef.current.style.top = `${window.visualViewport.offsetTop}px`;
      }

      if (chatHistoryRef.current && chatInputRef.current) {
        const inputHeight = chatInputRef.current.offsetHeight;
        chatHistoryRef.current.style.height = `${viewportHeight - inputHeight}px`;
      }
    }
  };

  const handleResize = () => {
    setIsMobile(window.innerWidth <= 768);
    setVH();
  };

  const debouncedHandleResize = debounce(handleResize, 250);

  const setVH = () => {
    let vh = window.innerHeight * 0.01;
    document.documentElement.style.setProperty('--vh', `${vh}px`);
  };

  useLayoutEffect(() => {
    window.addEventListener('resize', debouncedHandleResize);
    handleResize();
    setVH();

    if (isMobile) {
      window.visualViewport.addEventListener('resize', handleMobileLayout);
      window.visualViewport.addEventListener('scroll', handleMobileLayout);
    }

    return () => {
      window.removeEventListener('resize', debouncedHandleResize);
      if (isMobile) {
        window.visualViewport.removeEventListener('resize', handleMobileLayout);
        window.visualViewport.removeEventListener('scroll', handleMobileLayout);
      }
    };
  }, [isMobile]);

  useLayoutEffect(() => {
    adjustTextareaHeight();
  }, [message]);

  useEffect(() => {
    scrollToBottom();
  }, [chatHistory, streamingMessage, scrollToBottom]);

  const handleFocus = () => {
    if (isMobile) {
      setTimeout(handleMobileLayout, 100);
    }
  };

  const handleBlur = () => {
    if (isMobile) {
      setTimeout(() => {
        window.scrollTo(0, 0);
        handleMobileLayout();
      }, 100);
    }
  };

  return (
    <div className="chat-container" ref={chatContainerRef}>
      <div className="chat-history" ref={chatHistoryRef}>
        {currentQuestion.text && (
          <div className="current-question">
            <div>{currentQuestion.text}</div>
            <ul>
              {Object.entries(currentQuestion.answers).map(([key, value]) => (
                <li key={key}><strong>{key}:</strong> {value}</li>
              ))}
            </ul>
          </div>
        )}
        {chatHistory.map((chat, index) => (
          <div key={index} className={`chat-message ${chat.role}`}>
            <MarkdownRenderer markdownText={chat.content} />
          </div>
        ))}
      </div>

      <div>tool: {toolCallIndicator}</div>

      <div className="chat-input" ref={chatInputRef}>
        <textarea
          ref={messageRef}
          value={message}
          onChange={(e) => setMessage(e.target.value)}
          onKeyPress={handleKeyPress}
          onFocus={handleFocus}
          onBlur={handleBlur}
          placeholder="Talk to Juris..."
          disabled={isStreaming}
        />
        <button onClick={sendMessage} disabled={isStreaming}>Send</button>
      </div>
    </div>
  );
};

export default Chat;