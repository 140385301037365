// src/components/MeditatePage.js file
import React, { useState, useEffect, useRef } from 'react';
import { useNavigate } from 'react-router-dom';
import { supabase } from '../supabaseClient'; // Ensure this path is correct
import './MeditatePage.css'; // Ensure this path is correct

const MeditatePage = () => {
  const navigate = useNavigate();
  const [userName, setUserName] = useState('');
  const [time, setTime] = useState(60); // 5 minutes in seconds (TODO convert this to a prop that the user chooses)
  const [isTimeUp, setIsTimeUp] = useState(false);
  const [isVideoPaused, setIsVideoPaused] = useState(false);
  const [isAudioMuted, setIsAudioMuted] = useState(false);
  const [isReady, setIsReady] = useState(false);
  const [isStarting, setIsStarting] = useState(false); // Added for transition effect
  const videoRef = useRef(null);
  const audioRef = useRef(null);
  const timerRef = useRef(null);

  useEffect(() => {
    fetchUserData();
    return () => clearInterval(timerRef.current); // Clean up the interval on component unmount
  }, []);

  // TODO: Put this into global state
  const fetchUserData = async () => {
    try {
      const { data: { user } } = await supabase.auth.getUser();
      if (user) {
        const { data, error } = await supabase
          .from('user_data')
          .select('name')
          .eq('user_uuid', user.id)
          .single();

        if (error) throw error;
        if (data && data.name !== 'New User') {
            setUserName(data.name);
          }
      }
    } catch (error) {
      console.error('Error fetching user data:', error.message);
    }
  };

  const startFadeOut = () => {
    if (videoRef.current) {
      videoRef.current.classList.add('fade-out');
    }
    setIsTimeUp(true);
  };

  const startTimer = () => {
    timerRef.current = setInterval(() => {
      setTime(prevTime => {
        if (prevTime <= 1) {
          clearInterval(timerRef.current);
          startFadeOut();
          return 0;
        }
        return prevTime - 1;
      });
    }, 1000);
  }

  const stopTimer = () => {
    clearInterval(timerRef.current);
  }

  // Format time as mm:ss
  const formatTime = time => {
    const minutes = Math.floor(time / 60);
    const seconds = time % 60;
    return `${minutes}:${seconds < 10 ? '0' : ''}${seconds}`;
  };

  const handleStart = () => {
    setIsStarting(true); // Trigger transition effect
    setTimeout(() => {
      setIsReady(true);
      if (videoRef.current) {
        videoRef.current.play();
      }
      if (audioRef.current) {
        audioRef.current.play();
      }
      startTimer();
    }, 1000);
  };

  const toggleVideoPlayback = () => {
    if (videoRef.current) {
      if (isVideoPaused) {
        videoRef.current.play();
        startTimer();
      } else {
        videoRef.current.pause();
        stopTimer();
      }
      setIsVideoPaused(!isVideoPaused);
      console.log(`Video is now ${isVideoPaused ? 'playing' : 'paused'}`);
    }
  };

  const toggleAudioMute = () => {
    console.log('audio toggle: ', audioRef.current)
    if (audioRef.current) {
      audioRef.current.muted = !isAudioMuted;
      setIsAudioMuted(!isAudioMuted);
      console.log(`Audio is now ${isAudioMuted ? 'unmuted' : 'muted'}`);
    }
  };

  const handleQuestionsClick = () => {
    navigate('/practice');
  };

  return (
    <div className={`video-container ${isStarting ? 'fade-to-black': ''}`}>
      <img
        src="/left-arrow-gray-hi.png"
        alt="Back to home"
        className="back-arrow"
        onClick={() => navigate('/')}
      />
      {!isTimeUp && (
        <>
          {/* video and audio tags may be problematic for some browsers */}
          {/* Test: remove autoplay, only after 'Start Meditation' clicked */}
          {/* <video className="video-background" autoPlay loop muted ref={videoRef}> */}
          <video className="video-background" loop muted ref={videoRef}>
            <source src="/flower_of_life.mp4" type="video/mp4" />
            Your browser does not support the video tag.
          </video>
          <div className="controls">
            <div className="timer" ref={timerRef}>{formatTime(time)}</div>
            <button onClick={toggleVideoPlayback} className="control-button">
              {isVideoPaused ? 'Play' : 'Pause'}
            </button>
            <button onClick={toggleAudioMute} className="control-button">
              {isAudioMuted ? 'Unmute' : 'Mute'}
            </button>
          </div>
        </>
      )}
      {/* Test: remove autoplay, only after 'Start Meditation' clicked. Try loop on audio too */}
      {/* <audio autoPlay ref={audioRef} muted={isAudioMuted}> */}
      <audio loop ref={audioRef} muted={isAudioMuted}>
        <source src={isTimeUp ? "/30_min_bell.mp3" : "/meditation_audio.mp3"} type="audio/mp3" />
        Your browser does not support the audio element.
      </audio>
      {isTimeUp && (
        <div className="button-container-meditate">
          <button className="action-button-meditate" onClick={handleQuestionsClick}>
            <span>MBE<br />Practice</span>
          </button>
      </div>
      )}
      {!isReady && (
        <div className="popover">
          <div className="popover-content">  
            <h1>{`Welcome to Your Meditation Session, ${userName}`}</h1>
            <p>Take a deep breath and click to begin</p>
            <button onClick={handleStart} className="start-button">Start Meditation</button>
          </div>
        </div>
      )}
    </div>
  );
};


export default MeditatePage;
