//src/pages/home.js
import React from 'react';
import { useNavigate } from 'react-router-dom';
import HomePage from '../components/HomePage';

function Home() {
    return <HomePage />;
  }
  
export default Home;
