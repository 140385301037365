// src/App.js
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import './App.css';
import { supabase } from './supabaseClient';
import AuthModal from './authModal';
import { Analytics } from '@vercel/analytics/react';
import MarkdownRenderer from './cmp/MarkdownRenderer';
import { BrowserRouter as Router, Route, Routes } from 'react-router-dom';
import Home from './pages/Home';
import Meditate from './pages/Meditate';
import AboutPage from './pages/About';
import TestPage from './pages/Test';
import Chat from './pages/Chat';
import DevPage from './pages/Dev';
import OnboardingCards from './components/OnboardingCards';


function App() {
  const [userUuid, setUserUuid] = useState(null);
  const [sessionId, setSessionId] = useState(null);
  // const [message, setMessage] = useState('');
  // const [chatHistory, setChatHistory] = useState([]);
  const [currentQuestion, setCurrentQuestion] = useState({ text: '', answers: {} }); // State to store the current question
  const [showAuthModal, setShowAuthModal] = useState(false);
  // const messageRef = useRef(null); // Reference to the message input
  // const chatHistoryRef = useRef(null);
  const [isNewUser, setIsNewUser] = useState(false);
  const [showOnboarding, setShowOnboarding] = useState(false);



  // Test: make URL dependent on environment. .env.local loaded for every environment but test, .env.production loaded for production environment only (deployed?)
  // const REPLIT_BASE_URL = 'https://ai-tutor-backend-kappa.vercel.app/'; // Replace with your Replit URL
  const BACKEND_URL = process.env.REACT_APP_API_BASE_URL || 'http://localhost:3001';

  console.log('API Base URL frontend:', BACKEND_URL); // Add this line for debugging
  
  const api = axios.create({
    baseURL: BACKEND_URL,
    withCredentials: true,
    headers: {
      'Content-Type': 'application/json',
    },
  });

  useEffect(() => {
    // Check if user is logged in
    const checkSession = async () => {
      const { data } = await supabase.auth.getSession();
      const session = data.session;
      setUserUuid(session?.user?.id ?? null);

      if (!session) {
        setShowAuthModal(true);
      }
    };

    checkSession();
  }, []);

  useEffect(() => {
    if (userUuid) {
      const startSession = async () => {
        try {
          const response = await api.post('/api/user/startSession', { user_uuid: userUuid });
          setSessionId(response.data.sessionId);
          setCurrentQuestion({
            text: response.data.firstQuestion.question_text,
            answers: response.data.firstQuestion.possible_answers || {},
          });
          setIsNewUser(response.data.userProfile.isNewUser);
          setShowOnboarding(response.data.userProfile.isNewUser);          
          console.log('Session started:', response.data);
        } catch (error) {
          console.error('Error starting session:', error);
          if (error.response) {
            console.error('Error response:', error.response.data);
            console.error('Error status:', error.response.status);
            console.error('Error headers:', error.response.headers);
          } else if (error.request) {
            console.error('Error request:', error.request);
          } else {
            console.error('Error message:', error.message);
          }
        }
      }
      startSession();
  };

}, [userUuid, BACKEND_URL]);


  const handleAuthModalClose = () => {
    setShowAuthModal(false);
    const session = supabase.auth.session();
    setUserUuid(session?.user?.id ?? null);
  };

  const handleOnboardingComplete = () => {
    setShowOnboarding(false);
    };
    
  return (
    <Router>
      <div className="App">
        {showAuthModal && <AuthModal onClose={handleAuthModalClose} />}
        {showOnboarding && (
        <OnboardingCards
                userUuid={userUuid}
                isNewUser={isNewUser}
                onComplete={handleOnboardingComplete}
              />
        )}
        {!showOnboarding && (
          <Routes>
            <Route path="/" element={<Home />} />
            <Route path="/dev" element={<DevPage />} />
            <Route path="/about" element={<AboutPage />} />
            <Route path="/zen" element={<Meditate />} />
            <Route
              path="/practice"
              element={
                <Chat
                  api={api}                
                  userUuid={userUuid} 
                  sessionId={sessionId} 
                  currentQuestion={currentQuestion} 
                  setCurrentQuestion={setCurrentQuestion}
                />
              } 
            />
        </Routes>
        )}
        <Analytics />
      </div>
    </Router>
  );
}

export default App;